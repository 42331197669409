/**
 * Master Data Management
 * Master Data Management Server..
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface NPIOffice { 
    orgName: string;
    orgSubpart: string;
    officialFullName: string;
    officialFirstName: string;
    officialLastName: string;
    officialMiddleName: string;
    officialPhoneNum: number;
    officialTitle: string;
    certificationDate: string;
}

