import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SvgLoaderService {

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) { }

  public init(): void {
    this.matIconRegistry.addSvgIcon(
      'p-payer-plan', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/payer-plan.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'p-payer-lob', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/payer-lob.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'p-payer-user', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/payer-users.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'p-work-basket', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/work-basket.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'p-icon-video', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/icon-video.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'p-icon-faqs', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/icon-faqs.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'p-icon-provider-white', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/icon-provider-white.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'p-icon-provider', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/icon-provider.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'p-icon-facilities', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/icon-Facility.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'p-icon-single-user', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/icon-single-user.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'p-icon-user-roles', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/icon-user-roles.svg')
    );
    /////////////////////
    this.matIconRegistry.addSvgIcon(
      'p-icon-fee-scheduled', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/icon-FeeSched.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'p-icon-history', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/Icon-History.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'p-icon-malpractice-claims', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/Icon-Malpractice_Claims.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'p-icon-org-contract', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/Icon-Org_Contract.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'p-icon-ownership', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/Icon-Ownership.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'p-icon-subcontractors', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/Icon-Subcontractors.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'p-icon-verification', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/Icon-Verifications_Bundles.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'p-icon-background-check', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/Icon-Background_Checks.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'p-icon-committee-review', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/Icon-Committee_Reviews.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'p-icon-payer-lob', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/Icon-Direct_Payer_Contracts_LOB.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'p-icon-geo-alt', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/geo-alt.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'p-icon-question-circle', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/question-circle.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'p-icon-order-mgmt', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/icon-OrderManagement.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'p-icon-billing', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/icon-Billing.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'p-icon-flag', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/icon-flag.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'p-icon-flag-white', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/icon-flag-white.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'p-icon-split-white', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/icon-split-white.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'p-icon-deposit', this.domSanitizer.bypassSecurityTrustResourceUrl('../../../assets/icons/icon-deposit.svg')
    );
  }
}
