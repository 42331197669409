/**
 * Master Data Management
 * Master Data Management Server..
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NPIAddress } from './nPIAddress';
import { NPIPhysician } from './nPIPhysician';
import { NPIOffice } from './nPIOffice';


export interface NPI { 
    npiId?: number;
    npiNum: string;
    enumType: string;
    status: string;
    npiCreatedDT: string;
    npiUpdatedDT: string;
    addresses: Array<NPIAddress>;
    physicians?: Array<NPIPhysician>;
    offices?: Array<NPIOffice>;
}

